import { tv, type VariantProps } from 'tailwind-variants'

export const linkVariants = tv({
  base: 'decoration-current',
  variants: {
    underline: {
      always: 'underline',
      hover: 'hover:underline underline-offset-2',
      none: 'no-underline'
    },
    weight: {
      medium: 'font-medium'
    }
  },
  defaultVariants: {
    underline: 'none'
  }
})

export type Variants = VariantProps<typeof linkVariants>
